<template>
  <MaskView 
    borderStyle="border: 1px solid #d7d7d7"
    outerBorderStyle="1px dashed #02a7f0"
     mask-background-color="transparent" @click="click">
    <div class="position-absolute create-prod-wrap" >
      <div class="vo-column align-items-center justify-content-center h-100 w-100"
           v-has="'productMgmt::Create'">
        <img :src="require('@/assets/images/common/ic_add.svg')" alt="">
        <div class="caption">{{name}}</div>
      </div>
    </div>

  </MaskView>
</template>

<script>
import MaskView from "@/components/MaskView.vue";

export default {
  name: "CreateProduct",
  components: {MaskView},
  props: {
    name: {
      type: String,
      default: "Create Product",
    },
  },
  methods: {
    click() {
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">

.create-prod-wrap {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.caption {
  font-size: 14px;
  color: #02a7f0;
  text-align: center;
  margin-top: 10px;
}
</style>