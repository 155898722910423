var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { size: "lg", centered: "" },
      model: {
        value: _vm.modalVisible,
        callback: function($$v) {
          _vm.modalVisible = $$v
        },
        expression: "modalVisible"
      }
    },
    [
      [
        _vm.productDetail.id
          ? _c("div", { staticClass: "productDetailRow" }, [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "productDetailRow productDetailBorder" },
                  [_c("img", { attrs: { src: _vm.specs.deviceImage } })]
                ),
                _c(
                  "div",
                  { staticClass: "productDetailImageRow" },
                  _vm._l(_vm.specs.productPicture, function(item, key) {
                    return _c("b-col", { key: key, attrs: { lg: "3" } }, [
                      _c("img", {
                        staticClass: "vo-object-fit",
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: { src: item.value }
                      })
                    ])
                  }),
                  1
                )
              ]),
              _c("div", { staticClass: "productDetailContent" }, [
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "18px",
                      color: "#3eb1c8",
                      "font-weight": "700"
                    }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.productDetail.productSeriesName) + " "
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "13px",
                      color: "#aaaaaa",
                      "font-family": "'Arial Normal', 'Arial', sans-serif"
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.productDetail.remarks) + " ")]
                ),
                _c("p", [
                  _c("strong", { staticClass: "productDetailContentColor" }, [
                    _vm._v("Product Model #:")
                  ]),
                  _vm._v(" " + _vm._s(_vm.specs.modelName) + " ")
                ]),
                _c("p", [
                  _c("strong", { staticClass: "productDetailContentColor" }, [
                    _vm._v("Variant Code #:")
                  ]),
                  _vm._v(" " + _vm._s(_vm.specs.variantCode) + " ")
                ]),
                _c("p", [
                  _c("strong", { staticClass: "productDetailContentColor" }, [
                    _vm._v("Marketing Name:")
                  ]),
                  _vm._v(" " + _vm._s(_vm.specs.deviceName) + " ")
                ]),
                _c("p", [
                  _c("strong", { staticClass: "productDetailContentColor" }, [
                    _vm._v("Business Type:")
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.productDetail.oilType == 2
                          ? "CBD/THC Concentrates"
                          : "Nicotine"
                      ) +
                      " "
                  )
                ]),
                _c("p", [
                  _c("strong", { staticClass: "productDetailContentColor" }, [
                    _vm._v("Product Type:")
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.productDetail.type == 3 ? "Disposable" : "Batteries"
                      ) +
                      " "
                  )
                ]),
                _c("strong", { staticClass: "productDetailContentColor" }, [
                  _vm._v("Available Compatibility:")
                ]),
                _vm._v(" " + _vm._s(_vm.availableTankSizeText) + " "),
                _c(
                  "p",
                  _vm._l(_vm.availableCompatibilityList, function(
                    availableCompatibility
                  ) {
                    return _c(
                      "b-form-radio",
                      {
                        key: availableCompatibility.id,
                        staticClass: "custom-control-inline",
                        attrs: {
                          name:
                            "availableCompatibility" +
                            availableCompatibility.id,
                          value: availableCompatibility.id,
                          required: ""
                        },
                        model: {
                          value: _vm.availableCompatibilityId,
                          callback: function($$v) {
                            _vm.availableCompatibilityId = $$v
                          },
                          expression: "availableCompatibilityId"
                        }
                      },
                      [_vm._v(_vm._s(availableCompatibility.value) + " ")]
                    )
                  }),
                  1
                ),
                _c("strong", { staticClass: "productDetailContentColor" }, [
                  _vm._v("Available Tank Size:")
                ]),
                _vm._v(" " + _vm._s(_vm.availableMLText) + " "),
                _c(
                  "p",
                  _vm._l(_vm.taskCapacityList, function(taskCapacity) {
                    return _c(
                      "b-form-radio",
                      {
                        key: taskCapacity.id,
                        staticClass: "custom-control-inline",
                        attrs: {
                          name: "taskCapacityId" + taskCapacity.id,
                          value: taskCapacity.id,
                          required: ""
                        },
                        model: {
                          value: _vm.taskCapacityId,
                          callback: function($$v) {
                            _vm.taskCapacityId = $$v
                          },
                          expression: "taskCapacityId"
                        }
                      },
                      [_vm._v(_vm._s(taskCapacity.value) + " ")]
                    )
                  }),
                  1
                ),
                _c("strong", { staticClass: "productDetailContentColor" }, [
                  _vm._v("Other Option:")
                ]),
                _vm._v(" " + _vm._s(_vm.otherMLText) + " "),
                _c(
                  "p",
                  _vm._l(_vm.otherList, function(other) {
                    return _c(
                      "b-form-radio",
                      {
                        key: other.id,
                        staticClass: "custom-control-inline",
                        attrs: {
                          name: "otherOptionId" + other.id,
                          value: other.id,
                          required: ""
                        },
                        model: {
                          value: _vm.otherOptionId,
                          callback: function($$v) {
                            _vm.otherOptionId = $$v
                          },
                          expression: "otherOptionId"
                        }
                      },
                      [_vm._v(_vm._s(other.value) + " ")]
                    )
                  }),
                  1
                ),
                _c("p", [
                  _c("strong", { staticClass: "productDetailContentColor" }, [
                    _vm._v("Status:")
                  ]),
                  _c("span", { style: _vm.fontSizeStyle }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.specs.status === 0 ? "Enable" : "Disable")
                    )
                  ])
                ])
              ])
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "productDetailRow" },
          [
            _c(
              "b-button",
              {
                attrs: { variant: "outline-secondary" },
                on: { click: _vm.cancel }
              },
              [_vm._v(" Close ")]
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }