<template>
  <b-modal v-model="modalVisible" size="lg" centered>
    <template>
      <div class="productDetailRow" v-if="productDetail.id">
        <!-- 左边 -->
        <div>
          <div class="productDetailRow productDetailBorder">
            <img :src="specs.deviceImage" />
          </div>
          <div class="productDetailImageRow">
            <b-col v-for="(item, key) in specs.productPicture" :key="key" lg="3">
              <img :src="item.value" class="vo-object-fit"  style="width: 100%; height: 100%;"/>
            </b-col>
          </div>
        </div>
        <!-- 右边 -->
        <div class="productDetailContent">
          <p style="font-size: 18px; color: #3eb1c8; font-weight: 700">
            {{ productDetail.productSeriesName }}
          </p>
          <p
            style="
              font-size: 13px;
              color: #aaaaaa;
              font-family: 'Arial Normal', 'Arial', sans-serif;
            "
          >
            {{ productDetail.remarks }}
          </p>
          <p>
            <strong class="productDetailContentColor">Product Model #:</strong>
            {{ specs.modelName }}
          </p>
          <p>
            <strong class="productDetailContentColor">Variant Code #:</strong>
            {{ specs.variantCode }}
          </p>
          <p>
            <strong class="productDetailContentColor">Marketing Name:</strong>
            {{ specs.deviceName }}
          </p>
          <p>
            <strong class="productDetailContentColor">Business Type:</strong>
            {{
              productDetail.oilType == 2 ? "CBD/THC Concentrates" : "Nicotine"
            }}
          </p>
          <p>
            <strong class="productDetailContentColor">Product Type:</strong>
            {{ productDetail.type == 3 ? "Disposable" : "Batteries" }}
          </p>
          <strong class="productDetailContentColor"
            >Available Compatibility:</strong
          >
          {{ availableTankSizeText }}
          <p>
            <b-form-radio
              v-model="availableCompatibilityId"
              v-for="availableCompatibility in availableCompatibilityList"
              :key="availableCompatibility.id"
              class="custom-control-inline"
              :name="'availableCompatibility' + availableCompatibility.id"
              :value="availableCompatibility.id"
              required
              >{{ availableCompatibility.value }}
            </b-form-radio>
          </p>

          <strong class="productDetailContentColor"
            >Available Tank Size:</strong
          >
          {{ availableMLText }}
          <p>
            <b-form-radio
              v-model="taskCapacityId"
              v-for="taskCapacity in taskCapacityList"
              :key="taskCapacity.id"
              class="custom-control-inline"
              :name="'taskCapacityId' + taskCapacity.id"
              :value="taskCapacity.id"
              required
              >{{ taskCapacity.value }}
            </b-form-radio>
          </p>

          <strong class="productDetailContentColor">Other Option:</strong>
          {{ otherMLText }}
          <p>
            <b-form-radio
              v-model="otherOptionId"
              v-for="other in otherList"
              :key="other.id"
              class="custom-control-inline"
              :name="'otherOptionId' + other.id"
              :value="other.id"
              required
              >{{ other.value }}
            </b-form-radio>
          </p>
          <p>
            <strong class="productDetailContentColor">Status:</strong>
            <span :style="fontSizeStyle">
              {{ specs.status === 0 ? "Enable" : "Disable" }}</span
            >
          </p>
        </div>
      </div>
      <!-- 下边 -->
      <div class="productDetailRow">
        <b-button variant="outline-secondary" @click="cancel"> Close </b-button>
      </div>
    </template>
  </b-modal>
</template>

<style scoped>
.productDetailBorder {
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  width: 350px;
  height: 350px;
}
.productDetailRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}

.productDetailImageRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 5px;
  width: 350px;
  height: 125px;
}

.modal-header {
  display: none;
}
.modal-footer {
  display: none;
}
.productDetailContent {
  margin-left: 20px;
  flex:1;
}
.productDetailContentColor {
  color: #333333;
}
</style>

<script>
export default {
  props: {
    productDetail: {
      type: Object,
      default: () => {
        return {};
      },
    },
    noBtn: {
      type: String,
      default: "Close",
    },
    imageSrc: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      current: 0,
      taskCapacityId: 0,
      otherOptionId: 0,
      availableCompatibilityId: 0,
    };
  },
  computed: {
    modalVisible: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    taskCapacityList() {
      return this.productDetail.taskCapacityList;
    },
    availableCompatibilityList() {
      return this.productDetail.availableCompatibilityList;
    },
    otherList() {
      return this.productDetail.otherList;
    },
    specs() {
      return this.productDetail.specifications[this.current];
    },
    specifications() {
      return this.productDetail.specifications;
    },
    fontSizeStyle() {
      return this.specs.status == 1
        ? {
            color: "red",
          }
        : null;
    },
    availableTankSizeText() {
      if (
        this.availableCompatibilityList &&
        this.availableCompatibilityList.size > 0
      ) {
        return this.availableCompatibilityList.filter(
          (item) => item.id === this.availableCompatibilityId
        )[0].value;
      }
    },
    availableMLText() {
      if (this.taskCapacityList && this.taskCapacityList.size > 0) {
        return this.taskCapacityList.filter(
          (item) => item.id === this.taskCapacityId
        )[0].value;
      }
    },
    otherMLText() {
      if (this.otherList && this.otherList.size > 0) {
        return this.otherList.filter(
          (item) => item.id === this.otherOptionId
        )[0].value;
      }
    },
  },
  watch: {
    modalVisible(val) {
      if (val) {
        console.log(`${this.productDetail.taskCapacityId}`);
        this.taskCapacityId = this.productDetail.taskCapacityId;
        this.otherOptionId = this.productDetail.otherOptionId;
        this.availableCompatibilityId =
          this.productDetail.availableCompatibilityId;
      }
    },
    taskCapacityId() {
      this.currentMethods();
    },
    otherOptionId() {
      this.currentMethods();
    },
    availableCompatibilityId() {
      this.currentMethods();
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    currentMethods() {
      for (var i = 0; i < this.specifications.length; i++) {
        let item = this.specifications[i];
        if (
          item.taskCapacityId == this.taskCapacityId &&
          item.otherOptionId == this.otherOptionId &&
          item.availableCompatibilityId == this.availableCompatibilityId
        ) {
          this.current = i;
          break;
        }
      }
    },
  },
  mounted() {},
};
</script>
